import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
	routes: [

		{
			path: '/',
			name: 'main',
			component: () => import('../pages/MainForm.vue'),
		},
		{
			path: '/mySites',
			name: 'mySites',
			component: () => import('../pages/Photos.vue'),
		},
		{
			path: '/photos',
			name: 'photos',
			component: () => import('../pages/Photos.vue'),
		},
		{
			path: '/hobby',
			name: 'hobby',
			component: () => import('../pages/Photos.vue'),
		},
		{
			path: '/handmade',
			name: 'handmade',
			component: () => import('../pages/Photos.vue'),
		},
		{
			path: '/dysnai',
			name: 'dysnai',
			component: () => import('../pages/Dysnai.vue'),
		},
		{
			path: '/platezh',
			name: 'platezh',
			component: () => import('../pages/Platezh.vue'),
		},
	],
});

export default router;
