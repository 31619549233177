import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useRouter } from "vue-router";
import FileSaver from "file-saver";

export const useMainStore = defineStore('mainStore', () => {
	const birthday = "1975-07-26";
	let age = ref(0);
  const myPagesJson = ref([]);
  const mySitesJson = ref([]);
  const myPhotosJson = ref([]);
  const myHobbyJson = ref([]);
  const myHandmadeJson = ref([]);
  const myDysnaiJson = ref([]);
	const myTarifJson = ref([]);
	const myPotreblenieJson = ref([]);

  const sumJson = ref([]);
  const activeJson = ref([]);
  const album = ref([]);
  let fz = ref(18);
	const fzStep = ref(1);
  const fzpx = ref('18px');
  let lh = ref(1.5);
	const lhStep = ref(0.04);
  const lhem = ref("1.5em");
  let fontSize = ref({
		fontSize: '50px',
    lineHeight: '1.5em',

	});
	const appHeight = ref(window.innerHeight);
  const ph = ref(0);
  const mainPH = ref(0);
	let mainH = ref('100vh');
  const footerH = ref(6); 				//Одновременно менять в файле variables.sass
  const headerH = ref(6);					//Одновременно менять в файле variables.sass
	const dysnaiHeaderH = ref("9");
	const dysnaiHeaderHpx = ref("9vh");
	const dysnaiMainH = ref("");
	const dysnaiMainHpx = ref("");
	const activeDysnaiName = ref("pred");

  const view = ref('links');
  const photoCurrentPage = ref([]);
	const activePage = ref('/');
  // const path = ref('http://localhost:8080/assets/store/');
  const path = ref('https://rasvv.ru/assets/store/');
  const imgpath = ref('https://rasvv.ru/assets/img');
	const extension = ref("jpg");
	const avatarImg = ref('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg');
	const bcg_img =ref(imgpath+"background_main.jpg")

	const { offsetHeight } = document.getElementById('app');

	const router = useRouter();



	const setMainH = () => {
		// appHeight.value = 1065
		mainH.value = window.innerHeight - vhToPx(footerH.value) - vhToPx(headerH.value);
		dysnaiMainH.value = mainH.value - vhToPx(dysnaiHeaderH.value);
		dysnaiMainHpx.value = dysnaiMainH.value + 'px';
		dysnaiHeaderHpx.value = vhToPx(dysnaiHeaderH.value) + 'px';
		// mainH.value;

	}

	const vhToPx = (vh) => {return vh * window.innerHeight / 100; }

	const getAge = () => {
		const d = new Date(birthday);
		const now = new Date();

		const addOne =
		now.getMonth() - d.getMonth() >= 0 && now.getDate() - d.getDate() >= 0;

		const diff = now.getFullYear() - d.getFullYear();

		const age1 = diff + (addOne ? 1 : 0);
			
		let txt = "";
		let count = age1 % 100;
		count >= 5 && count <= 20 ? (txt = "лет") : (count = count % 10);
		count == 1
			? (txt = "год")
			: count >= 2 && count <= 4
			? (txt = "года")
			: (txt = "лет");
		age.value = age1 + " " + txt;
	}
	
	const fetching = async (jsName, myJson) => {
    const res = await fetch(`${path.value}${jsName}.json`);
    const data = await res.json();
    myJson.value = await data;	
		sumJson.value.push({
			name: jsName,
			data: myJson.value,
		})	
	}

	const fetchPlatezh = async () => {
		await router.push('/platezh');
	}

  const fetchAll = async () => {
		setMainH();
		getAge();
		fetching('pages', myPagesJson);
		
		await fetching('mySites', mySitesJson);
		await fetching('photos', myPhotosJson);
		await fetching('hobby', myHobbyJson);
		await fetching('handmade', myHandmadeJson);
		await fetching('dysnai', myDysnaiJson);
		await fetching('tarif', myTarifJson);
		await fetching('potreblenie', myPotreblenieJson);		
		// const routPath = router.currentRoute.value.path;
		const routName = router.currentRoute.value.name;
		console.log('routName');
		console.log(routName);
		
		// await router.push(routPath);
		if (routName === "platezh") {fetchPlatezh()}
		else if (routName != "main") {setPage({ name: routName, click: true })}
		

		window.addEventListener('resize', setMainH)
		
  };



	const setPage = (an) => {
		setView('links')
		activePage.value = an.name;
		router.push(an.name);
		if (an.click) {
			setActiveJson(an.name);
		}
		console.log(activeJson.value);
		if (an.album)
		{ console.log(getAlbum(an.album));
			setPhotoCurrentPage (getAlbum(an.album));
			// setAlbum(an.album),
		setView('grid')};
	}

	const setActiveJson = (active) => {
		activeJson.value = sumJson.value.filter((item) => item.name === active)[0].data	;
		console.log('activeJson.value');
		console.log(activeJson.value);
		
	}

  const setPhotoCurrentPage = (card) => {
    photoCurrentPage.value = card;
		console.log(photoCurrentPage);
		
	};

	const getAlbum = (al) => {
		return activeJson.value.filter((item)	=> item.title === al)[0]
	}

	const setView = (sv) => {
		console.log('setView');
		
		view.value = sv;
		console.log(view.value);
		
	}

	const setAlbum = (al) => {
		album.value = al;
	}

	const setFZ = (f_z) => {
		fz.value = fz.value + fzStep.value * f_z;
		fzpx.value = fz.value + 'px';
	}

	const setLH = (l_h) => {
		lh.value = lh.value + lhStep.value * l_h;
		lhem.value = lh.value + 'em';
	}

	const setFontSizeUp = () => {
		setLH(1);
		setFZ(1);
	}

	const setFontSizeDown = () => {
		setLH(-1);
		setFZ(-1);
	}

	const  saveFile = (array, fileName) => {
		console.log('before saveFile');
		
		const data = JSON.stringify(array);
		const blob = new Blob([data], { type: "application/json" });
		FileSaver.saveAs(blob, fileName);
	};

	// watch(offsetHeight, () => {
	// 	setMainH();
	
	// });


  return {
		myPagesJson,
    mySitesJson,
    myPhotosJson,
    myHobbyJson,
    myHandmadeJson,
    myDysnaiJson,
		myTarifJson,
		myPotreblenieJson,
	
		sumJson,
		activeJson,
    album,
    fz,
    lh,
		fontSize,
    ph,
    mainPH,
    footerH,
    headerH,


    view,
    photoCurrentPage,
		activePage,
    path,
    imgpath,
		bcg_img,
    jsonFile: '',
		fetching,
    fetchAll,
		fetchPlatezh,
		setPhotoCurrentPage,
		setPage,
		setView,
		extension,
		setAlbum,
		getAge,
		age,
		appHeight,
		mainH,
		avatarImg,
		dysnaiHeaderH,
		dysnaiMainH,
		dysnaiHeaderHpx,
		dysnaiMainHpx,
		activeDysnaiName,

		setFZ,
		setLH,
		setFontSizeUp,
		setFontSizeDown,
		setActiveJson,
		fzpx,
		lhem,
		saveFile,
  };
});
