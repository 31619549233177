<template>
  <div class="footer" :style="{ height: mainStore.footerH + 'vh' }">
    <el-row class="pad">
      <el-col :span="9" class="left_pan">
        <el-image
          style="height: 40px"
          contain
          :src="`${mainStore.imgpath}/Logo-small.png`"
          class="align-center"
        ></el-image>
      </el-col>
      <el-col :span="6" class="left_pan" cols="9">
        2020 - {{ new Date().getFullYear() }}
      </el-col>
      <el-col :span="9" class="left_pan">
        <el-image
          style="height: 30px"
          contain
          :src="`${mainStore.imgpath}/Logo-full.png`"
          @dblclick="mainStore.fetchPlatezh"
          class="align-center"
        ></el-image>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { useMainStore } from "../stores/mainStore";
import { useRouter } from "vue-router";
const router = useRouter();

const mainStore = useMainStore();
</script>

<style lang="sass" scoped>
@import '../assets/styles/main'

.footer, .el-footer
	margin: 0
	padding: 0 !important
	display: flex
	align-items: center
	background-color: $bcg_header
	color: $text_header

.left_pan
	display: flex
	justify-content: center

.pad
	width: 100vw
	padding: 0 !important
	display: flex
	justify-content: space-around
	align-items: center
</style>
