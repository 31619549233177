<template>
  <div class="header" :style="{ height: mainStore.headerH + 'vh' }">
    <el-radio-group
      v-model="mainStore.activePage"
      class="tabs"
      :style="{ height: mainStore.headerH + 'vh' }"
    >
      <el-radio-button
        id="mainBtn"
        v-for="item in mainStore.myPagesJson"
        :key="item.name"
        :label="item.title"
        :value="item.name"
        size="large"
        @click="mainStore.setPage(item)"
      >
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script setup >
import { useMainStore } from "../stores/mainStore";

const mainStore = useMainStore();
</script>

<style lang="sass" scoped>
@import '../assets/styles/header'
</style>
